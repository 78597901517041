import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, isEmpty, isNil } from "utils/lodashExtracts";
import Segment from "utils/segment";
import CharlesMeta from "components/common/meta/CharlesMeta";
import StructuredWebPage from "components/common/meta/StructuredWebPage";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Layout } from "components/common";
import Cta from "components/common/Cta";
import PressBanner from "components/Press/PressBanner";
import TopSection from "templates/Pathologies/common/TopSection";
import AboutPathology from "templates/Pathologies/common/AboutPathology";
import TestimonyPathologyTopBottom from "templates/Pathologies/common/Testimonies/TestimonyPathologyTopBottom";
import Treatments from "templates/Pathologies/common/Treatments";
import FaqPathology from "templates/Pathologies/common/FaqPathology";
import HowItWorksSection from "templates/Pathologies/common/HowItWorksSection";
import ScientistSection from "templates/Pathologies/common/ScientistSection";
import PathoSurFooter from "templates/Pathologies/common/PathoSurFooter";

import "./styles.sass";
import T from "types/index";
import StructuredFAQ from "components/common/meta/StructuredFAQ";
import { pathologiesDaughterBreadcrumbs } from "templates/Blog/common/Breadcrumb";
import ApiService from 'utils/oms/apis';
import { getPathologySpecialty, formatNextMeetDate } from 'utils/oms/utils';
import {AirtableMarkdown} from "components/common/utils/AirtableMarkdown";
import WhyCharles from "templates/Pathologies/common/Treatments/WhyCharles";
import Section from "components/common/Section";
import ReviewSection from "components/ReviewSection";

const anchors = (pathology: T.Charles.ProductTemplateData) => {
  const scientistLabel =
    pathology.scientists.length > 1 ? "Comité scientifique" : "Directeur Scientifique";

  return [
    { id: "how-it-works-anchor", label: "Comment ça marche ?" },
    { id: "review-anchor", label: "Avis" },
    { id: "scientist-anchor", label: scientistLabel },
    { id: "about-anchor", label: "À propos" },
    { id: "faq-anchor", label: "FAQ" },
  ];
};

const Pathology = ({
  data: { pathologyRecord },
}: {
  data: {
    pathologyRecord: T.Charles.PathologyRecord;
  };
}) => {
  const pathology: T.Charles.Pathology = pathologyRecord.data;
  const templateData: T.Charles.ProductTemplateData = get(pathology, 'product_template_data[0].data')
  const category: T.Charles.Category = get(pathology, 'category[0].data')
  const ctaLabel = templateData.cta_label || "Consultez un spécialiste";
  const startSlug = templateData.start_slug;
  // TODO LUCAS Refactor this idea of passing all props (cta_label + start_slug + cta) it doesn't make sense
  const cta = (
    <Cta
      text={ctaLabel || "Consultez un spécialiste"}
      className="new-primary-cta pathology-cta"
      path={startSlug}
    />
  );
  const aboutItems: T.Standard.AccordionItem[] = templateData.about_items.map((item: T.Charles.ItemRecord) => {
    return {
      title: isNil(item.data.title) ? '' : item.data.title.childMarkdownRemark.rawMarkdownBody,
      text: isNil(item.data.text) ? '' : item.data.text.childMarkdownRemark.rawMarkdownBody
  }})
  const metadata: T.Charles.Metadata = get(pathology, "metadata[0].data");
  const itemsToQA = (item: T.Charles.ItemRecord) => ({
    question: isNil(item.data.title) ? '' : item.data.title.childMarkdownRemark.rawMarkdownBody,
    answer: isNil(item.data.text) ? '' : item.data.text.childMarkdownRemark.rawMarkdownBody,
  })
  const aboutQA = templateData.about_items.map(itemsToQA)
  const faqQA = templateData.faq_items.map(itemsToQA)
  const allQA = aboutQA.concat(faqQA)

  const [nextMeetDate, setNextMeetDate] = useState('')

  useEffect(() => {
    const fetchNextMeetDate = async () => {
      const specialtySlug: string = getPathologySpecialty(category.slug, pathology.slug)
      const { data } = await ApiService.getConsultationNextFreeSlot(specialtySlug)
      const timeSlotLabel: string = formatNextMeetDate(data.timeSlot) as string
      setNextMeetDate(timeSlotLabel)
    }

    fetchNextMeetDate().catch(console.error)
  }, [])

  return (
    <Layout
      showLinksInFooter={false}
      footerCtaLabel={ctaLabel}
      footerCtaPath={startSlug}
    >
      <CharlesMeta
        title={metadata.title}
        description={metadata.meta_description}
        segmentCategory={Segment.CATEGORIES.PATHOLOGIES}
        canonicalPathname={metadata.canonical_url}
        noIndex={metadata.no_index === true}
      />
      <StructuredWebPage
        title={templateData.h1.internal.content}
        description={metadata.meta_description}
      />
      <StructuredFAQ data={allQA} />
      <div className="pathology-template" id="pathology-template">
        <TopSection
          h1={templateData.h1}
          subtitle={templateData.subtitle}
          cta_label={templateData.cta_label}
          start_slug={templateData.start_slug}
          border_icon={category.border_icon}
          main_video={templateData.main_video}
          reinsurance_items={templateData.reinsurance_items}
          cta={cta}
          anchors={anchors(templateData)}
          nextMeetDate={nextMeetDate}
        />
        <HowItWorksSection
          how_title={templateData.how_title}
          how_items={templateData.how_items}
          start_slug={templateData.start_slug}
          cta={cta}
        />
        <PressBanner withBorders className="mb-0" />
        <Section className="pathology-treatments-section">
          <WhyCharles startSlug={templateData.start_slug} cta={cta} />
        </Section>
        <ReviewSection />
        <ScientistSection
          scientists_title={templateData.scientists_title}
          scientists={templateData.scientists}
          start_slug={templateData.start_slug}
          cta_label={templateData.cta_label}
        />
        <AboutPathology
          about_title={<AirtableMarkdown markdown={templateData.about_title}/>}
          about_definition={<AirtableMarkdown markdown={templateData.about_definition}/>}
          bottomBorder={false}
          aboutItems={aboutItems}
        />
        <FaqPathology
          start_slug={templateData.start_slug}
          faq_title={templateData.faq_title}
          faq_items={templateData.faq_items}
          videos={templateData.videos}
          cta={cta}
        />
        <PathoSurFooter
          breadcrumbLinks={pathologiesDaughterBreadcrumbs(category, pathology)}
          pathologyRecord={pathologyRecord}
        />
      </div>
    </Layout>
  );
};

export default Pathology;

export const pageQuery = graphql`
  query ($id: String!) {
    pathologyRecord: airtablePathologies(id: { eq: $id }) {
      id
      data {
        slug
        status
        breadcrumb_label
        metadata {
          ...AirtableMetadataFields
        }
        category {
          id
          data {
            slug
            name
            border_icon { ...AirtableImagesConstrainedFields }
          }
        }
        product_template_data {
          ...AirtableProductTemplateDataFields
        }
      }
    }
  }
`;

export const fragments = graphql`
  fragment AirtableProductTemplateDataFields on AirtableProductTemplateData {
    id
    data {
      cta_label
      faq_title
      scientists_title
      start_slug
      treatments_title
      treatments_text
      h1 { ...AirtableMarkdownFields }
      about_title { ...AirtableMarkdownFields }
      about_definition { ...AirtableMarkdownFields }
      how_title { ...AirtableMarkdownFields }
      subtitle { ...AirtableMarkdownFields }
      testimonies_percent { ...AirtableMarkdownFields }
      scientists { ...AirtableScientistsFields }
      about_items { ...AirtableItemsFields }
      faq_items { ...AirtableItemsFields }
      how_items { ...AirtableItemsFields }
      reinsurance_items { ...AirtableItemsFields }
      main_video { ...AirtableYoutubeVideosFields }
      testimonies_background { ...AirtableImagesFullWidthFields }
      testimonies_list { ...AirtableTestimoniesFields }
      treatments_list { ...AirtableTreatmentsFields }
      videos { ...AirtableYoutubeVideosFields }
    }
  }
  fragment AirtableMotherTemplateDataFields on AirtableMotherTemplateData {
    id
    data {
      cta_label
      start_slug
      pathologies_title
      pathologies_subtitle
      faq_title
      h1 { ...AirtableMarkdownFields }
      subtitle { ...AirtableMarkdownFields }
      faq_items { ...AirtableItemsFields }
      reinsurance_items { ...AirtableItemsFields }
      main_image { ...AirtableImagesConstrainedFields }
    }
  }
  fragment AirtableImagesConstrainedFields on AirtableImages {
    id
    data {
      alt
      title
      image {
        localFiles {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          }
          publicURL
        }
      }
    }
  }
  fragment AirtableImagesFullWidthFields on AirtableImages {
    id
    data {
      alt
      title
      image {
        localFiles {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
          }
          publicURL
        }
      }
    }
  }
  fragment AirtableItemsFields on AirtableItems {
    id
    data {
      title { ...AirtableMarkdownFields }
      text { ...AirtableMarkdownFields }
      image { ...AirtableImagesConstrainedFields }
    }
  }
  fragment AirtableYoutubeVideosFields on AirtableYoutubeVideos {
    id
    data {
      title
      video_id
      published_at
      description
      alt
      image {
        localFiles {
          childImageSharp {
            gatsbyImageData(width: 300, placeholder: DOMINANT_COLOR)
          }
          publicURL
        }
      }
    }
  }
  fragment AirtableMetadataFields on AirtableMetadata {
    id
    data {
      title
      meta_description
      no_index
      canonical_url
    }
  }
  fragment AirtableScientistsFields on AirtableScientists {
    id
    data {
      description
      education_and_titles
      full_name
      last_name
      name
      position
      practitioner_cities_of_practice
      practitioner_speciality
      qualification
      scientific_comity
      specialty
      image_gray { ...AirtableImagesConstrainedFields }
      image_landscape { ...AirtableImagesConstrainedFields }
      image_regular { ...AirtableImagesConstrainedFields }
      image_square { ...AirtableImagesConstrainedFields }
      image_yellow { ...AirtableImagesConstrainedFields }
    }
  }
  fragment AirtableTestimoniesFields on AirtableTestimonies {
    id
    data {
      text
      author
      before
      after
      image { ...AirtableImagesConstrainedFields }
    }
  }
  fragment AirtableTreatmentsFields on AirtableTreatments {
    id
    data {
      name
      description
      image { ...AirtableImagesConstrainedFields }
    }
  }
  fragment AirtableMarkdownFields on AirtableField {
    childMarkdownRemark {
      html
      rawMarkdownBody
    }
    internal {
      content
    }
  }
`;
